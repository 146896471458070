import { NgModule } from '@angular/core';
import { MsalGuard } from '@azure/msal-angular';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '@environments/environment';
import { LayoutComponent } from '@shared/components';
import { ROUTES } from '@consts';

const appRoutes: Routes = [
    {
        path: 'page',
        component: LayoutComponent,
        children: [
            {
                path: ROUTES.DASHBOARD,
                loadChildren: () => import('./pages/dashboard/dashboard.module').then((module) => module.DashboardModule),
                canActivate: [MsalGuard],
            },
            {
                path: ROUTES.MATERIAL_NUMBER,
                loadChildren: () => import('@pages/search/search.module').then((module) => module.SearchModule),
                canActivate: [MsalGuard],
            },
            {
                path: ROUTES.TECHNICAL_ATTRIBUTE,
                loadChildren: () => import('@pages/search/search.module').then((module) => module.SearchModule),
                canActivate: [MsalGuard],
            },
            {
                path: ROUTES.PART_NUMBER,
                pathMatch: 'full',
                loadChildren: () => import('./pages/part-number/part-number.module').then((module) => module.PartNumberModule),
                canActivate: [MsalGuard],
            },
            {
                path: ROUTES.WORK_ORDERS,
                loadChildren: () => import('./pages/work-orders/work-orders.module').then((module) => module.WorkOrdersModule),
                canActivate: [MsalGuard],
            },
            {
                path: ROUTES.CLUSTER_VIEW,
                loadChildren: () => import('./pages/cluster-view/cluster-view.module').then((module) => module.ClusterViewModule),
                canActivate: [MsalGuard],
            },
            {
                path: ROUTES.ALL_MATERIAL,
                loadChildren: () => import('./pages/all-material/all-material.module').then((module) => module.AllMaterialModule),
                canActivate: [MsalGuard],
            },
        ],
        canActivate: [MsalGuard],
    },
    {
        path: '**',
        redirectTo: ROUTES.PAGE_DASHBOARD,
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            enableTracing: environment.debug.router,
            anchorScrolling: 'enabled',
            scrollPositionRestoration: 'enabled',
            relativeLinkResolution: 'legacy',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
