<div *ngIf="!isNA" class="date-last-po">
    <span
        #tooltipEl
        class="material-icons-outlined info"
        data-bs-toggle="tooltip"
        data-bs-html="true"
        data-bs-placement="bottom"
        title="{{ 'allMaterial.qtystock' | translate }}: {{ qtystock }}"
    >
        info
    </span>
    <span class="value-price">
        {{ qtystock }}
    </span>
</div>

<div class="value na" *ngIf="isNA">
    <span *ngIf="isNA">{{ 'common.na' | translate }}</span>
</div>
