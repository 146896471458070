import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

declare const bootstrap: { Tooltip: new (el: Element) => any };

@Component({
    selector: 'app-long-desc-renderer',
    templateUrl: './long-desc-renderer.component.html',
    styleUrls: ['./long-desc-renderer.component.scss'],
})
export class LongDescRendererComponent implements ICellRendererAngularComp, AfterViewInit {
    @ViewChild('tooltipEl') tooltipEl: ElementRef;
    isNA = true;
    longDescription: string;
    constructor() {}

    ngAfterViewInit(): void {
        this.setupCellTooltips();
    }

    private setupCellTooltips(): any {
        // Bootstrap tooltip initialization
        if (this.tooltipEl) {
            return new bootstrap.Tooltip(this.tooltipEl.nativeElement as Element);
        }
    }

    agInit(params: ICellRendererParams): void {
        this.refresh(params);
    }

    refresh(params: ICellRendererParams): boolean {
        const longDesc = params.value as string;
        if (longDesc != null) {
            this.isNA = longDesc === null;
            this.longDescription = longDesc;
        }
        return true;
    }
}
