<div class="l-navbar" [ngClass]="{ show: displaySidebar }" id="nav-bar">
    <p class="mx-2 search-by-sidebar">{{ 'common.searchBy' | translate }}</p>
    <nav class="nav">
        <div>
            <div class="nav_list bloc-menu mx-2">
                <ul class="navbar-nav side-nav ps-0">
                    <li>
                        <a [routerLink]="['dashboard']" routerLinkActive="active-link" class="nav-link">
                            {{ 'common.dashboard' | translate }}
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="['search/work-orders']" routerLinkActive="active-link" class="nav-link">
                            {{ 'common.workOrder' | translate }}
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="['search/part-number']" aria-current="page" routerLinkActive="active-link" class="nav-link">
                            {{ 'common.partNumber' | translate }}</a
                        >
                    </li>
                    <li>
                        <a [routerLink]="['search/material-number']" routerLinkActive="active-link" class="nav-link">
                            {{ 'common.materialNumber' | translate }}</a
                        >
                    </li>
                    <li>
                        <a [routerLink]="['search/technical-attribute']" routerLinkActive="active-link" class="nav-link">
                            {{ 'common.materialType' | translate }}</a
                        >
                    </li>

                    <li>
                        <a [routerLink]="['search/cluster-view']" routerLinkActive="active-link" class="nav-link">
                            {{ 'common.clusterView' | translate }}
                        </a>
                    </li>
                    <li>
                        <a [routerLink]="['search/all-material']" routerLinkActive="active-link" class="nav-link">
                            {{ 'common.allMaterial' | translate }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</div>
