import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
    MsalGuard,
    MsalInterceptor,
    MsalModule,
    MsalService,
    MSAL_GUARD_CONFIG,
    MSAL_INSTANCE,
    MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';

import { TranslationService } from '@/app/shared/services';
import { LoaderComponent, HeaderComponent, LayoutComponent } from '@shared/components';
import { MSALInstanceFactory, MSALGuardConfigFactory, MSALInterceptorConfigFactory } from '@config';
import { FooterComponent } from './components/footer/footer.component';
import { PanelMaterialInformationComponent } from './components/panel-material-information/panel-material-information.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragAndDropDirective } from './directive/drag-and-drop.directive';
import { QtyInStockRendererComponent } from './components/qty-in-stock-renderer/qty-in-stock-renderer.component';
import { LongDescRendererComponent } from './components/long-desc-renderer/long-desc-renderer.component';

export const HttpLoaderFactory = (http: HttpClient): TranslateHttpLoader => new TranslateHttpLoader(http);

@NgModule({
    declarations: [
        HeaderComponent,
        LayoutComponent,
        LoaderComponent,
        FooterComponent,
        PanelMaterialInformationComponent,
        SidebarComponent,
        DragAndDropDirective,
        QtyInStockRendererComponent,
        LongDescRendererComponent,
    ],
    imports: [
        MsalModule,
        CommonModule,
        RouterModule,
        HttpClientModule,
        ClipboardModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
            isolate: true, // to isolate the service to be completly isolated instance
        }),
    ],
    providers: [
        TranslationService,
        MsalGuard,
        MsalService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory,
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory,
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory,
        },
        {
            provide: ErrorHandler,
            useClass: ApplicationinsightsAngularpluginErrorService,
        },
    ],
    exports: [TranslateModule, LoaderComponent, PanelMaterialInformationComponent, DragAndDropDirective],
    schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
    constructor(private translation: TranslationService) {
        translation.init();
    }
}
