import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

declare const bootstrap: { Tooltip: new (el: Element) => any };

@Component({
    selector: 'app-qty-in-stock-renderer',
    templateUrl: './qty-in-stock-renderer.component.html',
    styleUrls: ['./qty-in-stock-renderer.component.scss'],
})
export class QtyInStockRendererComponent implements ICellRendererAngularComp, AfterViewInit {
    @ViewChild('tooltipEl') tooltipEl: ElementRef;
    isNA = true;
    qtystock: string;
    constructor() {}

    ngAfterViewInit(): void {
        this.setupCellTooltips();
    }

    private setupCellTooltips(): any {
        // Bootstrap tooltip initialization
        if (this.tooltipEl) {
            return new bootstrap.Tooltip(this.tooltipEl.nativeElement as Element);
        }
    }

    agInit(params: ICellRendererParams): void {
        this.refresh(params);
    }

    refresh(params: ICellRendererParams): boolean {
        const qtyinstock = params.value as string;
        if (qtyinstock != null) {
            this.isNA = qtyinstock === null;
            this.qtystock = qtyinstock;
        }
        return true;
    }
}
