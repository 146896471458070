import { Component, OnDestroy, OnInit } from '@angular/core';

import { Language } from '@types';
import { AuthService, SearchService, TranslationService } from '@/app/shared/services';
import { SidebarService } from '@app/shared/services/sidebar.service';
import { UserService } from '../../services/user.service';
import { UserDefDialogComponent, UserObject } from '@/app/pages/user-def-dialog/user-def-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
    public userName: string;
    public userEmail: string;
    public currentLanguage = Language.EN;
    public Language = Language;
    userData: any;
    currentUser: any;
    alertNotificationres: any;

    alertNotificationdata: any = [];
    alertNotificationerror: any = [];
    alertUsersList: any;
    subscription: Subscription;

    loggedInUserName: string;

    currentDate = new Date();
    alertNotificationerrors: any = [];

    constructor(
        private authService: AuthService,
        private translationService: TranslationService,
        private sidebarService: SidebarService,
        private userService: UserService,
        private dialog: MatDialog,
        private searchService: SearchService
    ) {
        this.translationService.currentLanguage.subscribe((currentLanguage: Language) => {
            this.currentLanguage = currentLanguage;
        });
    }

    ngOnInit(): void {
        this.authService.currentUser.subscribe((currentUser) => {
            this.currentUser = currentUser;
            this.userName = currentUser?.name ?? '';
            this.userEmail = currentUser?.username ?? '';

            if (this.userName) {
                this.subscription = this.searchService.getAlertUser(this.userName).subscribe((response: any) => {
                    this.alertUsersList = response;
                });
            }
        });
        // this.searchService.currentAlertNotifyMessage.subscribe((currentAlertNotifyMessage) => {
        //     if (currentAlertNotifyMessage === true) {
        //         this.subscription = this.searchService.getAlertUser(this.userName).subscribe((response: any) => {
        //             this.alertUsersList = response;
        //         });
        //     }
        // });

        this.searchService.getstockAlertNotifictaions.subscribe((stockAlertNotifictaionHistory) => {
            this.alertNotificationres = stockAlertNotifictaionHistory;
            if (this.alertNotificationres !== null) {
                if (this.alertNotificationres.notified === true) {
                    const obj = {
                        createdAt: this.alertNotificationres.createdAt,
                        materialNumber: this.alertNotificationres.materialNumber,
                        message: 'You will be get notified via mail once the material will be back',
                    };
                    this.alertNotificationdata.push(obj);
                    this.subscription = this.searchService.getAlertUser(this.userName).subscribe((response: any) => {
                        this.alertUsersList = response;
                    });
                } else {
                    const obj1 = {
                        dateTime: this.alertNotificationres.dateTime,
                        message: 'We already informed, You will be get notified via mail once the material will be back',
                    };
                    this.alertNotificationerror.push(obj1);
                    this.alertNotificationerrors = this.alertNotificationerror.filter(
                        (item: any, index: any, self: any) => index === self.indexOf(self.find((f: any) => f.message === item.message))
                    );
                }
            }
        });
    }

    logout(): void {
        this.authService.logout();
    }

    changeLanguage(language: Language): void {
        this.translationService.changeLanguage(language);
    }

    openUserDefDialog(): void {
        const userDef = this.currentUser as UserObject;
        this.dialog
            .open(UserDefDialogComponent, {
                data: userDef,
                maxHeight: '80vh',
                width: '670px',
                autoFocus: false,
                hasBackdrop: true,
                disableClose: false,
            })
            .afterClosed()
            .subscribe((results: UserObject) => {
                const userData = results;
                this.userService.saveUser(results).subscribe((result) => {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    this.userData = result;
                    this.userService.setUserData(userData);
                });
            });
    }

    ngOnDestroy(): void {
        this.translationService.currentLanguage.unsubscribe();
    }
}
