<nav class="navbar navbar-expand-sm navbar-light flex-column">
    <div class="container-fluid header-with-burger p-0">
        <a class="navbar-brand text-primary" style="margin-right: 14px; margin-left: 40px" href="#">
            <img src="assets/images/logo/logo-TotalEnergies.svg" alt="Total Energies" class="logo" />
        </a>
        <a class="navbar-brand text-primary m-0" href="#">
            <img src="assets/images/logo/logo_IMS.svg" alt="IMS" class="logo" />
        </a>
        <span class="logo-text d-none d-md-block"> {{ 'common.title' | translate }}</span>
        <ul class="navbar-nav ms-auto d-flex flex-row me-5">
            <li class="nav-item">
                <div class="d-flex ms-md-4">
                    <!-- <span data-hj-suppress class="navbar-text text-uppercase ms-2 d-none d-md-inline">{{ userName }}</span> -->
                    <div class="dropdown" style="margin-right: 4px; top: 2px">
                        <button
                            type="button"
                            id="accountDropdown"
                            class="btn btn-tertiary btn-circle border-0"
                            data-bs-display="static"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <span class="offscreen">{{ 'header.settings' | translate }}</span>
                            <span class="material-icons-outlined" aria-hidden="true">account_circle</span>
                        </button>
                        <ul
                            class="dropdown-menu dropdown-menu-end"
                            style="width: 218px; padding-top: 0px; padding-bottom: 0px; margin-top: -10px"
                            aria-labelledby="accountDropdown"
                        >
                            <span data-hj-suppress class="text-userName">{{ userName }}</span>
                            <li>
                                <a class="dropdown-item logout" id="openDialog" (click)="openUserDefDialog()">{{
                                    'header.profile' | translate
                                }}</a>
                            </li>
                            <li>
                                <a class="dropdown-item logout" id="logout" (click)="logout()">{{ 'header.logout' | translate }}</a>
                            </li>
                        </ul>
                    </div>

                    <div class="dropdown" style="margin-right: -18px">
                        <button
                            type="button"
                            id="accountDropdown"
                            class="btn btn-circle btn-tertiary"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <span class="material-icons-outlined" aria-hidden="true">notifications</span>
                        </button>

                        <div class="notification-container">
                            <i class="notification-icon fa fa-bell"></i>
                            <div class="notification-badge" *ngIf="alertUsersList > 0">{{ alertUsersList }}</div>
                        </div>
                        <div class="dropdown-menu dropdown-menu-end notifybutton" aria-labelledby="accountDropdown">
                            <span style="font-size: 18px; font-weight: 500; padding-left: 20px"> Notifications</span>

                            <!-- <div style="font-size: 18px" *ngIf="alertUsersList?.length === 0">No Alert Notifications available</div> -->

                            <div class="dropdown-item divide-line" *ngFor="let notification of alertNotificationdata">
                                <span style="font-weight: 500">{{ notification.materialNumber }} </span> material number
                                <span style="padding-left: 130px; color: blue">{{ notification.createdAt | date : 'dd-MM-YYYY' }}</span>

                                <br />
                                <span>{{ notification.message }}</span>
                            </div>

                            <div class="dropdown-item divide-line" *ngFor="let notifierror of alertNotificationerrors">
                                <span>{{ notifierror?.message }}</span>
                                <span style="padding-left: 20px; color: blue">{{ notifierror?.dateTime | date : 'dd-MM-YYYY' }}</span>
                            </div>

                            <!-- <div class="dropdown-item divide-line" *ngIf = "alertUsersList > 0">
                                <span style="font-weight: 500">{{ alertuser.materialNumber }}</span> material number
                                <span style="padding-left: 130px; color: blue">{{ currentDate | date : 'dd-MM-YYYY' }}</span>
                                <br />
                                <span *ngIf="alertuser.availableQty > 0"> Good news! Your item is back</span>
                            </div> -->
                        </div>
                    </div>
                </div>
            </li>
            <li class="nav-item dropdown d-none d-lg-block">
                <a
                    class="nav-link dropdown-toggle"
                    data-bs-toggle="dropdown"
                    id="languageChange"
                    href="#"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                    >{{ currentLanguage | titlecase }}</a
                >
                <div class="dropdown-menu dropdown-menu-end">
                    <a
                        class="dropdown-item"
                        id="EngLang"
                        (click)="changeLanguage(Language.EN)"
                        [ngClass]="{ active: currentLanguage === Language.EN }"
                        >{{ 'common.en' | translate }}</a
                    >
                    <a
                        class="dropdown-item"
                        (click)="changeLanguage(Language.FR)"
                        [ngClass]="{ active: currentLanguage === Language.FR }"
                        >{{ 'common.fr' | translate }}</a
                    >
                </div>
            </li>
        </ul>
    </div>
</nav>
