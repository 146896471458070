<div *ngIf="!isNA && longDescription" class="date-last-po">
    <span
        #tooltipEl
        class="material-icons-outlined info"
        data-bs-toggle="tooltip"
        data-bs-html="true"
        data-bs-placement="bottom"
        title="{{ 'allMaterial.longDescription' | translate }}: {{ longDescription }}"
    >
        info
    </span>
    <span class="value-price">
        {{ longDescription }}
    </span>
</div>

<div class="value na" *ngIf="isNA || !longDescription" style="margin-left: 2rem">
    <span *ngIf="isNA || !longDescription">{{ 'common.na' | translate }}</span>
</div>
